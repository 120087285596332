h5{
    text-align: center;
    margin-top: 10px;
}

p{
    text-align: left;
}

.full{
    margin-top: 10px;
    font-size: 18px;
}

.footer p{
    background-color: #007BFF;
    width: 100px;
    color : #fff;
}

img{
    pointer-events: none
}